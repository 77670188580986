<template>
  <div class="member-shop-point-exchange-table">
    <el-table :data="displayData">
      <EmptyBlock slot="empty" />
      <el-table-column label="性質" prop="type" align="center" />
      <el-table-column label="名稱" prop="name" align="center" />
      <el-table-column label="公開顯示" align="center">
        <template slot-scope="scope">
          <Tag :type="get(scope.row, 'isPublic.tagType')">{{ get(scope.row, 'isPublic.label') }}</Tag>
        </template>
      </el-table-column>
      <el-table-column label="兌換規則" prop="rules" align="center" />
      <el-table-column label="是否永豐價保" align="center">
        <template slot-scope="scope">
          <Tag :type="get(scope.row, 'isPaymentPromise.tagType')">{{ get(scope.row, 'isPaymentPromise.label') }}</Tag>
        </template>
      </el-table-column>
      <el-table-column label="排序" prop="order" align="center" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <TableEditBtnGroup
            @delete="onRowDelete(scope.row)"
            @edit="onRowEdit(scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>

    <DeleteDialog
      v-if="modal.delete"
      title="提醒"
      content="刪除後將無法復原，確定要刪除？"
      width="40%"
      :loading="loading.delete"
      @close="modal.delete = false"
      @delete="deleteData"
    />
  </div>
</template>

<script>
import { defineComponent, computed, reactive, ref } from 'vue'
import EmptyBlock from '@/components/EmptyBlock.vue'
import { productTypeConfig } from '@/config/memberShop'
import { DeleteMemberShopProduct } from '@/api/memberShop'
import Tag from '@/components/Tag/Tag.vue'
import TableEditBtnGroup from '@/components/Button/TableEditBtnGroup.vue'
import DeleteDialog from '@/components/Dialog/DeleteDialog'
import { get, map } from 'lodash'
import { useRouter } from 'vue-router/composables'
import store from '@/store'

export default defineComponent({
  name: 'MemberShopPointExchangeTable',
  components: { EmptyBlock, Tag, TableEditBtnGroup, DeleteDialog },
  props: {
    tableData: { type: Array, default: () => [] },
  },
  emits: ['refresh'],
  setup (props, { emit }) {
    const router = useRouter()
    const shopId = computed(() => store.getters.shop)
    const loading = reactive({
      delete: false,
    })

    const displayData = computed(() => {
      const data = props.tableData

      return map(data, i => {
        const price = i.price
        const externalPoint = i.externalPointAmount
        const point = externalPoint || i.pointExchangeAmount
        let rules = ''
        if (!price) rules = `${point} 點${externalPoint ? '(第三方)' : ''}可兌換一個`
        if (price) rules = `${point} 點，加上 $ ${price}元`
        return {
          id: i.id,
          type: get(productTypeConfig, `${i.type}.label`),
          name: i.name,
          isPublic: i.isPublic ? { label: '是', tagType: 'action' } : { label: '否', tagType: 'info' },
          isPaymentPromise: i.isPaymentPromise ? { label: '是', tagType: 'action' } : { label: '否', tagType: 'info' },
          rules,
          order: i.order,
        }
      })
    })
    const selectRow = ref(null)
    const modal = reactive({
      delete: false,
    })

    const onRowEdit = (row) => {
      router.push({
        name: 'MemberShopProductExchangeEdit',
        params: { id: row.id },
      })
    }
    const onRowDelete = (row) => {
      selectRow.value = row
      modal.delete = true
    }

    const deleteData = async () => {
      if (loading.delete) return
      loading.delete = true
      const [, err] = await DeleteMemberShopProduct({
        shopId: shopId.value,
        id: get(selectRow.value, 'id'),
      })
      loading.delete = false
      if (err) return window.$message.error(err)
      modal.delete = false
      window.$message.success('刪除成功！')
      emit('refresh')
    }

    return { get, loading, displayData, selectRow, modal, deleteData, onRowEdit, onRowDelete }
  },
})
</script>

<style lang="postcss" scoped>

</style>
